export const currentEnv: string = "dev";

const env: string = currentEnv;

export const Env = env;

const API_DEV_URL = "https://easy-market-api.withvolkeno.com";
const API_DEMO_URL = "https://easy-market-api.withvolkeno.com";
const API_PROD_URL = "https://api.easymarket.sn";

const VENDEUR_APIKEY_DEMO =
  "9badbf3b1dbc8fa78e622f10c2e83ac38cf51a7b42a42fb9a5ed18051c21e6b3";
const VENDEUR_APIKEY_PROD =
  "9badbf3b1dbc8fa78e622f10c2e83ac38cf51a7b42a42fb9a5ed18051c21e6b3";

export const APP_URL_DEV = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_PROD = "https://web.easymarket.sn/";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}

function processApikey() {
  if (env === "prod") return VENDEUR_APIKEY_PROD;
  if (env === "demo") return VENDEUR_APIKEY_DEMO;
  return VENDEUR_APIKEY_DEMO;
}

export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const VendeurApikey = processApikey();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";

export const IntechApikey = "554ACA96-F050-442D-8E29-BEE77FAE821A";

export const GoogleClientId =
  "701177325323-33rje48k1alj39qmb9vspat9urmmrojn.apps.googleusercontent.com";
  
