import photo1 from "../../../assets/images/appImages/founder_1.png";
import photo2 from "../../../assets/images/appImages/founder_2.png";
import photo3 from "../../../assets/images/appImages/team_3.png";
import photo4 from "../../../assets/images/appImages/team_4.png";

export const TeamData = [
    {
        id: 1,
        photo: photo1,
        name: "Adjaratou K. NDIAYE",
        role: "Chef de projet",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
    {
        id: 2,
        photo: photo3,
        name: "Red Team de Bakeli",
        role: "Developpement mobile",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
    {
        id: 3,
        photo: photo2,
        name: "Volkeno Team",
        role: "Developpement WEB",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
    {
        id: 4,
        photo: photo4,
        name: "Fatim SEYE",
        role: "Digital marketer",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
]